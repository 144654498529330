import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { useCookies } from "vue3-cookies";
import type { AuthObject } from "@/types";
import { trackPage } from "@/services/mixpanel";

const { cookies } = useCookies();

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "root",
    component: () => import("@/views/RootView.vue"),
    meta: {
      requiredAuthorization: false,
    },
  },
  {
    path: "/courses",
    name: "courses",
    component: () => import("@/views/CoursesView.vue"),
    meta: {
      requiredAuthorization: true,
      roles: ["admin", "teacher"],
    },
  },
  {
    path: "/courses/:id",
    name: "course",
    component: () => import("@/views/CourseView.vue"),
    meta: {
      requiredAuthorization: true,
      roles: ["admin", "teacher", "student"],
    },
  },
  {
    path: "/courses/:id/edit",
    name: "designer",
    component: () => import("@/views/CourseDesigner.vue"),
    meta: {
      requiredAuthorization: true,
      roles: ["admin", "teacher"],
    },
  },
  {
    path: "/courses/:id/dashboard",
    name: "dashboard",
    component: () => import("@/views/CourseDashboard.vue"),
    meta: {
      requiredAuthorization: true,
      roles: ["admin", "teacher"],
    },
  },
  {
    path: "/courses/:id/assessment",
    name: "assessment",
    component: () => import("@/views/AssessmentView.vue"),
    meta: {
      requiredAuthorization: true,
      roles: ["admin", "teacher"],
    },
  },
  {
    path: "/program",
    name: "program",
    component: () => import("@/views/ProgramView.vue"),
    meta: {
      requiredAuthorization: true,
      roles: ["admin", "teacher", "student"],
    },
  },
  {
    path: "/me",
    name: "me",
    component: () => import("@/views/MyView.vue"),
    meta: {
      requiredAuthorization: true,
      roles: ["student"],
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/RegisterView.vue"),
    meta: {
      requiredAuthorization: false,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginView.vue"),
    meta: {
      requiredAuthorization: false,
    },
  },
  {
    path: "/reset",
    name: "reset",
    component: () => import("@/views/ResetPasswordView.vue"),
    meta: {
      requiredAuthorization: false,
    },
  },
  {
    path: "/recover",
    name: "recover",
    component: () => import("@/views/RecoverView.vue"),
    meta: {
      requiredAuthorization: false,
    },
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("@/views/TermsView.vue"),
    meta: {
      requiredAuthorization: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to) => {
  if (to.meta.requiredAuthorization) {
    if (isAuthenticated()) {
      if (isAllowed(to.meta.roles as string[])) {
        trackPage(to.path);

        return true;
      } else {
        return "/me";
      }
    } else {
      return "/login";
    }
  } else {
    return true;
  }
});

function isAuthenticated() {
  return cookies.isKey("awesomo");
}

function isAllowed(roles: Array<string>) {
  if (roles) {
    const role: string = (cookies.get("awesomo") as unknown as AuthObject).role;
    return roles.includes(role) ? true : false;
  } else {
    return false;
  }
}

export default router;
