import { useCookies } from "vue3-cookies";
import mixpanel from "mixpanel-browser";
import { reportError, getErrorMessage } from "@/services/errorHandling";
import { AxiosRequestConfig } from "axios";
import { Resource, Activity, AuthObject } from "@/types";

mixpanel.init(process.env.VUE_APP_MIXPANEL_PROJECT_TOKEN);

const { cookies } = useCookies();
let programName = "unknown";

if (cookies.isKey("awesomo")) {
  const programId = (cookies.get("awesomo") as unknown as AuthObject)
    .program_id as string;

  switch (programId) {
    case "1":
      programName = "Frontendutvecklare 22";
      break;
    case "2":
      programName = "Javascriptutvecklare 22";
      break;
    case "3":
      programName = "Mobilapputvecklare 22";
      break;
    case "4":
      programName = "Systemutveckare 22";
      break;
  }
}

function trackPage(route: string) {
  try {
    mixpanel.track("page view", {
      route: route,
      program: programName,
    });
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
  }
}

function trackRequest(req: AxiosRequestConfig) {
  try {
    mixpanel.track("request", {
      type: req.method,
      url: req.url,
      program: programName,
    });
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
  }
}

function trackResource(resource: Resource, activity: Activity) {
  let type = "link";
  if (resource.url.includes("github") || resource.url.includes("gitlab")) {
    type = "git";
  }
  if (resource.url.includes("vimeo")) {
    type = "vimeo";
  }
  if (resource.url.includes("youtube")) {
    type = "youtube";
  }
  if (resource.url.includes("doc") || resource.url.includes("pdf")) {
    type = "doc";
  }

  try {
    mixpanel.track("resource", {
      type: type,
      url: resource.url,
      activity: activity.title,
      program: programName,
    });
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
  }
}

export { trackPage, trackRequest, trackResource };
