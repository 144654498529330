import { defineStore } from "pinia";

type NotificationTypes = "error" | "warning" | "alert" | "info";

type Notice = {
  id: number;
  type: NotificationTypes;
  timestamp: string;
  actionRequired: boolean;
  glance?: boolean;
  confirmed?: boolean;
  msg: string;
  icon?: string | null;
};

export const useSystemStore = defineStore("system", {
  state: () => {
    return {
      notifications: [] as Array<Notice>,
    };
  },
  actions: {
    async notify(
      type: NotificationTypes,
      msg: string,
      actionRequired: boolean
    ) {
      const GLANCE_TIME = 3000; // 3s

      // if notification doesnt already exists, dont do a copy
      if (
        !this.notifications.find((n) => n.id === this.notifications.length - 1)
      ) {
        const notice: Notice = {
          id: this.notifications.length,
          type: type,
          timestamp: new Date().toISOString(),
          actionRequired: actionRequired,
          confirmed: false,
          glance: true,
          msg: msg,
          icon: null,
        };

        this.notifications.push(notice);

        if (notice.glance) {
          setTimeout(() => {
            this.notifications.splice(notice.id - 1, 1);
          }, GLANCE_TIME);
        }
      }
    },
    async removeNotification(idx: number) {
      this.notifications.splice(idx, 1);
    },
    async testNotifications() {
      setTimeout(() => {
        this.notifications.push({
          id: this.notifications.length,
          type: "info",
          timestamp: "2022-05-10T09:00",
          actionRequired: false,
          confirmed: false,
          msg: "Super informant information.",
        });
      }, 1000);

      setTimeout(() => {
        this.notifications.push({
          id: this.notifications.length,
          type: "error",
          timestamp: "2022-05-17T08:00",
          actionRequired: false,
          confirmed: false,
          msg: "Warning Will Robinson, warning!",
        });
      }, 2000);

      setTimeout(() => {
        this.notifications.push({
          id: this.notifications.length,
          type: "warning",
          timestamp: "2022-04-19T07:00",
          actionRequired: false,
          confirmed: false,
          msg: "Hey, watch out!",
        });
      }, 3000);
    },
  },
  getters: {},
});

/*

High-attention

Alerts (immediate attention required)
Errors (immediate action required)
Exceptions (system anomalies, something didn’t work)
Confirmations (potentially destructive actions that need user confirmation to proceed)
Medium-attention

Warnings (no immediate action required)
Acknowledgments (feedback on user actions)
Success messages
Low-attention

Informational messages (aka passive notifications, something is ready to view)
Badges (typically on icons, signifying something new since last interaction)
Status indicators (system feedback)

*/
