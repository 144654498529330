import axios from "axios";
import router from "@/router";
import { useCookies } from "vue3-cookies";
import { trackRequest } from "./mixpanel";
import {
  Course,
  Module,
  ModuleGoal,
  Activity,
  User,
  ActivityAssessment,
  ModuleGoalAssessment,
  Invitation,
  AuthObject,
  InvitationResponse,
  RegistrationResponse,
  RegistrationDetails,
  RecoverDetails,
  Submission,
} from "@/types";
import { reportError, getErrorMessage } from "./errorHandling";

const { cookies } = useCookies();

if (process.env.NODE_ENV === "production") {
  axios.defaults.baseURL = process.env.VUE_APP_HOST; // api
}

if (cookies.isKey("awesomo")) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${
    (cookies.get("awesomo") as unknown as AuthObject).token
  }`;
} else {
  router.push("/login");
}

axios.interceptors.request.use(
  (config) => {
    trackRequest(config);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/* AUTH */

export async function login(
  email: string,
  password: string
): Promise<AuthObject> {
  const route = "/auth";

  try {
    const resp = await axios.post(route, { email, password });
    if (resp.data.token) {
      // Set cookie
      cookies.set("awesomo", resp.data);

      axios.defaults.headers.common["Authorization"] = `Bearer ${
        (cookies.get("awesomo") as unknown as AuthObject).token
      }`;

      if (resp.data.role === "teacher") {
        router.push("/courses");
      }

      if (resp.data.role === "student") {
        router.push("/me");
      }
    }

    return resp.data;
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
    return {} as AuthObject;
  }
}

export async function getMe() {
  const route = "/profile";

  try {
    const resp = await axios.get(route);
    return resp.data;
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
  }
}

export async function getAssessments(course_id: number) {
  const route = `/courses/${course_id}/assessments`;

  try {
    const resp = await axios.get(route);
    return resp.data;
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
  }
}

/* DESIGNER / COURSE */

export async function getCourses(): Promise<Course[]> {
  const route = "/courses";

  try {
    const resp = await axios.get(route);
    return resp.data as Array<Course>;
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
    return [];
  }
}

export async function getMyCourses(): Promise<Course[]> {
  const user_id = (cookies.get("awesomo") as unknown as AuthObject).id;
  const route = `/courses?owner_id=${user_id}`;

  try {
    const resp = await axios.get(route);

    return resp.data as Array<Course>;
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
    return [];
  }
}

export async function createCourse(title: string): Promise<Course> {
  const route = `/courses`;

  try {
    const resp = await axios.post(route, { title });
    return resp.data as Course;
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
    return {} as Course;
  }
}

export async function getCourse(course_id: number): Promise<Course> {
  const route = `/courses/${course_id}?include=modules+activities+activityAssessments+moduleAssessments`;

  try {
    const resp = await axios.get(route);
    return resp.data as Course;
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
    return {} as Course;
  }
}

export async function patchCourse(course: Course): Promise<void> {
  const route = `/courses/${course.course_id}`;
  const c = { ...course };
  delete c.course_id;

  try {
    await axios.patch(route, c);
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
  }
}

export async function deleteCourse(courseId: number): Promise<void> {
  const route = `/courses/${courseId}`;

  try {
    await axios.delete(route);
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
  }
}

/* DESIGNER / MODULE */

export async function createModule(courseId: number): Promise<Module> {
  const route = `/courses/${courseId}/modules`;

  try {
    const resp = await axios.post(route, { title: "Default Module Title" });
    return resp.data as Module;
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
    return {} as Module;
  }
}

export async function patchModule(module: Module): Promise<Module> {
  const route = `/modules/${module.module_id}`;
  const m: Module = { ...module };
  delete m.module_id;
  delete m.course_id;

  try {
    const resp = await axios.patch(route, m);
    return resp.data as Module;
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
    return {} as Module;
  }
}

export async function deleteModule(moduleId: number): Promise<void> {
  const route = `/modules/${moduleId}`;

  try {
    await axios.delete(route);
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
  }
}

export async function createModuleGoal(
  moduleGoal: ModuleGoal
): Promise<ModuleGoal> {
  const route = `/modules/${moduleGoal.module_id}/goals`;
  try {
    const resp = await axios.post(route, moduleGoal);
    return resp.data as ModuleGoal;
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
    return {} as ModuleGoal;
  }
}

export async function patchModuleGoal(
  moduleGoal: ModuleGoal
): Promise<ModuleGoal> {
  const route = `/modules/${moduleGoal.module_id}/goals/${moduleGoal.goal_id}`;
  try {
    const resp = await axios.patch(route, moduleGoal);
    return resp.data as ModuleGoal;
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
    return {} as ModuleGoal;
  }
}

export async function deleteModuleGoal(moduleGoal: ModuleGoal): Promise<void> {
  const route = `/modules/${moduleGoal.module_id}/goals/${moduleGoal.goal_id}`;

  try {
    await axios.delete(route);
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
  }
}

/* DESIGNER / ACTIVITY */

export async function createActivity(moduleId: number): Promise<Activity> {
  const route = `/modules/${moduleId}/activities`;

  try {
    const resp = await axios.post(route, { title: "Default Activity Title" });
    return resp.data as Activity;
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
    return {} as Activity;
  }
}

export async function patchActivity(activity: Activity): Promise<void> {
  const route = `/activities/${activity.activity_id}`;
  const a: Activity = { ...activity };
  delete a.activity_id;
  delete a.module_id;

  try {
    await axios.patch(route, a);
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
  }
}

export async function deleteActivity(activity: Activity): Promise<void> {
  const route = `/activities/${activity.activity_id}`;

  try {
    await axios.delete(route);
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
  }
}

export async function updateActivityAssessment(
  activity: Activity,
  score: number
): Promise<ActivityAssessment> {
  const route = `/activities/${activity.activity_id}/assessments`;

  try {
    const assessment: ActivityAssessment = await axios.put(route, { score });
    return assessment;
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
    return {} as ActivityAssessment;
  }
}

export async function submitExamination(
  activity: Activity,
  content: string
): Promise<Submission> {
  const route = `/activities/${activity.activity_id}/submissions`;

  try {
    const resp = await axios.post(route, { content });
    return resp.data as Submission;
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
    return {} as Submission;
  }
}

export async function getSubmission(activity: Activity) {
  const user_id = (cookies.get("awesomo") as unknown as AuthObject).id;
  const route = `/activities/${activity.activity_id}/submissions/${user_id}`;

  try {
    const submission = await axios.get(route);
    return submission.data;
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
    return {} as ActivityAssessment;
  }
}

export async function getCourseSubmissions(activity_id: number) {
  const route = `/activities/${activity_id}/submissions`;

  try {
    const submissions = await axios.get(route);
    return submissions.data as Submission[];
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
    return [] as Submission[];
  }
}

export async function gradeSubmission(
  activity_id: number,
  student_id: number,
  grade: string
) {
  const route = `/activities/${activity_id}/submissions/${student_id}`;

  try {
    await axios.patch(route, { grade });
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
  }
}

export async function gradeCourse(
  course_id: number,
  student_id: number,
  grade: string
) {
  const route = `/courses/${course_id}/assessments/${student_id}`;

  try {
    await axios.post(route, { grade });
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
  }
}

/* USER HANDLING */

export async function getCourseUsers(course_id: number): Promise<User[]> {
  const route = `/courses/${course_id}/users?include=moduleAssessments+activityAssessments`;

  try {
    const resp = await axios.get(route);
    return resp.data;
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
    return [];
  }
}

export async function getCourseAssessments(course_id: number): Promise<{
  moduleAssessments?: Array<ModuleGoalAssessment>;
  activityAssessments?: Array<ActivityAssessment>;
}> {
  const route = `/courses/${course_id}/assessments`;

  try {
    const resp = await axios.get(route);
    return resp.data;
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
    return {};
  }
}

/* COURSE */

export async function updateModuleAssessment(
  goal: ModuleGoal,
  score: number
): Promise<void> {
  const route = `/modules/${goal.module_id}/goals/${goal.goal_id}/assessments`;

  try {
    await axios.put(route, { score });
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
  }
}

/* REGISTER, INVITE, RECOVER */

export async function inviteUsers(
  courseId: number,
  emails: Array<string>
): Promise<InvitationResponse[]> {
  const route = `/courses/${courseId}/invitations`;

  try {
    const resp = await axios.post(route, emails);
    return resp.data as Array<InvitationResponse>;
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
    return [] as Array<InvitationResponse>;
  }
}

export async function getRegistration(
  token: string
): Promise<RegistrationResponse> {
  const route = `/registration`;

  try {
    const resp = await axios.get(route, {
      params: { token },
    });
    return resp.data as RegistrationResponse;
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
    return {} as RegistrationResponse;
  }
}

export async function getInvites(): Promise<Invitation[]> {
  const route = `/profile/invitations`;

  try {
    const resp = await axios.get(route);
    return resp.data as Array<Invitation>;
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
    return [];
  }
}

export async function acceptInvitation(course_id: number): Promise<void> {
  const route = `/profile/invitations/${course_id}/`;

  try {
    await axios.post(route);
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
  }
}

export async function registerUser(
  token: string,
  user: RegistrationDetails
): Promise<RegistrationResponse> {
  const route = `/registration?token=${token}`;
  try {
    const resp = await axios.post(route, user);
    return resp.data as RegistrationResponse;
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
    return {} as RegistrationResponse;
  }
}

export async function recoverPassword(token: string, data: RecoverDetails) {
  const route = `/recover?token=${token}`;

  try {
    const resp = await axios.patch(route, data);
    return resp.data as RegistrationResponse;
  } catch (err) {
    throw getErrorMessage(err);
  }
}

export async function requestPasswordReset(email: string) {
  const route = `/recover`;
  try {
    await axios.post(route, { email });
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
    return {} as RegistrationResponse;
  }
}

export async function removeUserFromCourse(
  course_id: number,
  user_id: number
): Promise<void> {
  const route = `/courses/${course_id}/invitations/${user_id}`;

  try {
    await axios.delete(route);
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
  }
}
