import { DateObj, EditorJS, AuthObject } from "@/types";
import { sub, getWeek } from "date-fns";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

// Time
const dateConverter = (date: string | undefined): DateObj | undefined => {
  if (date !== undefined) {
    const d = sub(new Date(date), { hours: 2 });

    return {
      date: d.getDate(),
      time: `${doubleDigits(d.getHours())}.${doubleDigits(d.getMinutes())}`,
      week: getWeek(d, { weekStartsOn: 1, firstWeekContainsDate: 2 }),
      month: d.toLocaleString("sv-SE", { month: "short" }),
      year: d.getFullYear(),
      weekDay: d.toLocaleString("sv-SE", { weekday: "short" }),
    };
  }
};

function semester(date: string) {
  if (date === null) return "-";
  const d = new Date(date);
  const month = d.getMonth();
  const year = `${d.getFullYear()}`.slice(-2);
  if (month < 6) {
    return `VT${year}`;
  } else {
    return `HT${year}`;
  }
}

// EditorJS utils
function ejsCoverter(obj: { blocks: Array<EditorJS> }) {
  let str = "";
  obj.blocks.forEach((block) => {
    if (block.type === "header") {
      str += `<h${block.data?.level}>${block.data?.text}</h${block.data?.level}>`;
    }

    if (block.type === "ingress") {
      str += `<p class="ingress">${block.data?.text}</p>`;
    }

    if (block.type === "paragraph") {
      str += `<p>${block.data?.text}</p>`;
    }
  });

  return str;
}

/* MISC */
function doubleDigits(num: number): string {
  return num < 10 ? `0${num}` : `${num}`;
}

const isEmail = (email: string) => {
  return /\S+@\S+\.\S+/.test(email);
};

function ensure<T>(argument: T | undefined | null): T {
  if (argument === undefined || argument === null) {
    throw new TypeError("This value was promised to be there.");
  }

  return argument;
}

function isAdmin() {
  if (cookies.isKey("awesomo")) {
    const role = (cookies.get("awesomo") as unknown as AuthObject).role;
    return role === "teacher" || role === "admin" ? true : false;
  } else {
    return false;
  }
}

export {
  doubleDigits,
  dateConverter,
  semester,
  ejsCoverter,
  isEmail,
  ensure,
  isAdmin,
};
