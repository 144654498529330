import { RegistrationDetails, AuthObject } from "./../types/index";
import { defineStore } from "pinia";
import { useCookies } from "vue3-cookies";
import {
  login,
  registerUser,
  getInvites,
  acceptInvitation,
  requestPasswordReset,
  recoverPassword,
  getAssessments,
  getMe,
} from "@/services/apiService";
import router from "@/router";
import { Invitation } from "@/types";
import { reportError, getErrorMessage } from "@/services/errorHandling";

export const useUserStore = defineStore("user", {
  state: () => {
    return {
      user: {
        currentCourseId: null,
        prefs: {
          theme: "theme-light",
        },
        me: {} as AuthObject,
      },
    };
  },
  actions: {
    async login(email: string, password: string): Promise<AuthObject> {
      try {
        const me = await login(email, password);
        this.user.me = me;
        return me as AuthObject;
      } catch (err) {
        reportError({ message: getErrorMessage(err) });
        return {} as AuthObject;
      }
    },
    async logout(): Promise<void> {
      const { cookies } = useCookies();
      try {
        this.user.me = {} as AuthObject;
        cookies.remove("awesomo");
        await router.push("/login");
      } catch (err) {
        reportError({ message: getErrorMessage(err) });
      }
    },
    async checkState(): Promise<string> {
      const { cookies } = useCookies();
      if (cookies.isKey("awesome")) {
        const m = await getMe();
        if (!m) {
          console.log("Session not found.");
          return "unknown";
        } else {
          console.log("Session found.");
          return (cookies.get("awesomo") as unknown as AuthObject).role;
        }
      } else {
        return "unknown";
      }
    },
    async recoverPassword(token: string, password: string) {
      try {
        await recoverPassword(token, { password: password });
      } catch (err) {
        throw getErrorMessage(err);
      }
    },
    async requestPasswordReset(email: string) {
      try {
        await requestPasswordReset(email);
      } catch (err) {
        reportError({ message: getErrorMessage(err) });
      }
    },
    async registerAccount(
      token: string,
      data: RegistrationDetails
    ): Promise<void> {
      try {
        await registerUser(token, {
          name: data.name,
          password: data.password,
          program_id: data.program_id,
        });
      } catch (err) {
        reportError({ message: getErrorMessage(err) });
      }
    },
    async getMyInvites() {
      try {
        const resp: Array<Invitation> = await getInvites();
        return resp;
      } catch (err) {
        reportError({ message: getErrorMessage(err) });
      }
    },
    async acceptInvitation(course_id: number): Promise<void> {
      try {
        await acceptInvitation(course_id);
      } catch (err) {
        reportError({ message: getErrorMessage(err) });
      }
    },
    async getAssessments(course_id: number) {
      try {
        return await getAssessments(course_id);
      } catch (err) {
        reportError({ message: getErrorMessage(err) });
      }
    },
  },
  getters: {
    getTheme: (state) => {
      return state.user.prefs.theme;
    },
  },
});
